<template>
  <div>
    <InactiveFBMUsersHead
            :countFilterParams="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="toggleFilter"
            @selectAll="selectAll"
            @filter="$emit('filter')"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
      <InactiveFBMUsersFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <InactiveFBMUsersTable
              :selectedNow="selectedNow"
              :isModalInfoPopup="isModalInfoPopup"
              :itemId="itemId"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @updateTable="$emit('updateTable')"
              @reload="$emit('reload')"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


  import InactiveFBMUsersHead from "./InactiveFBMUsersHead/InactiveFBMUsersHead";
  import InactiveFBMUsersFilter from "./InactiveFBMUsersFilter/InactiveFBMUsersFilter";
  import InactiveFBMUsersTable from "./InactiveFBMUsersTable/InactiveFBMUsersTable";

  export default {
    name: "InactiveFBMUsersTableSection",

    components: {
      InactiveFBMUsersHead,
      InactiveFBMUsersFilter,
      InactiveFBMUsersTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      isModalInfoPopup: {
        type: Boolean,
        default: false,
      },
      itemId: Number,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>
