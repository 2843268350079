import { render, staticRenderFns } from "./InactiveFBMUsersHead.vue?vue&type=template&id=11844615&scoped=true"
import script from "./InactiveFBMUsersHead.vue?vue&type=script&lang=js"
export * from "./InactiveFBMUsersHead.vue?vue&type=script&lang=js"
import style0 from "./InactiveFBMUsersHead.vue?vue&type=style&index=0&id=11844615&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11844615",
  null
  
)

export default component.exports