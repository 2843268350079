var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[(_vm.$store.getters.getInactiveFBMUsers.length > 0 && _vm.$store.getters.getInactiveFBMUsersLoading !== true)?[_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'forbiddenGoods_ID',
            'common_date',
            'forbiddenGoods_Transaction',
            'forbiddenGoods_Store',
            'forbiddenGoods_InactiveFBMUsersKeyword',
            'common_manage',
            'forbiddenGoods_ToBalance',
            'forbiddenGoods_Refund',
          ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('inactiveFBMUsers_id.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('inactiveFBMUsers_created.localization_value.value')))]),(_vm.isAdmin)?_c('th',[_vm._v(" "+_vm._s(_vm.$t('common_user.localization_value.value')))]):_vm._e(),_c('th',[_vm._v(_vm._s(_vm.$t('inactiveFBMUsers_storage.localization_value.value')))]),_c('th',{staticClass:"hide-1400",attrs:{"width":"100%"}})])]),_c('tbody',_vm._l((_vm.$store.getters.getInactiveFBMUsers),function(item,index){return _c('tr',{key:index},[_c('td',[_c('span',{staticClass:"table-link btn-style"},[_vm._v(" #"+_vm._s(item.id)+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD MMM, YYYY"))+" ")]),(_vm.isAdmin)?_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(item.storage)+" ")]),_c('td',{staticClass:"hide-1400"})])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getInactiveFBMUsers),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('InactiveFBMUsersTableMobile',{attrs:{"item":item},on:{"changeInfoPopup":function($event){return _vm.$emit('changeInfoPopup', true, item.payment_transaction.id)},"approve":function($event){return _vm.$emit('approve', item.id)},"refund":function($event){return _vm.$emit('refund', item.id)}}})],1)}),0)]):_vm._e(),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getInactiveFBMUsersCommonList.next_page_url !== null && _vm.$store.getters.getInactiveFBMUsers.length > 0)?_c('ShowMore',{attrs:{"count":_vm.$store.getters.getInactiveFBMUsersCommonList.total - _vm.$store.getters.getInactiveFBMUsersForPage < _vm.$store.getters.getInactiveFBMUsersForPage ?
                    _vm.$store.getters.getInactiveFBMUsersCommonList.total - _vm.$store.getters.getInactiveFBMUsersForPage:
                    _vm.$store.getters.getInactiveFBMUsersForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e(),_c('ExportBtn',{staticClass:"table-bottom-btn__right",on:{"downloadFiles":type => _vm.$emit('downloadFiles', type)}})],1)])])]:_vm._e(),(_vm.$store.getters.getInactiveFBMUsersLoading === false && _vm.$store.getters.getInactiveFBMUsers.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }