<template>
  <div class="fragment">
    <InactiveFBMUsersTableSection
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :isModalInfoPopup="isModalInfoPopup"
            :itemId="itemId"
            @resetFilter="resetFilter"
            @showMore="showMore"
            @changeFilter="changeFilter"
            @reload="reload"
            @downloadFiles="downloadFiles"
    />
  </div>

</template>

<script>
  import InactiveFBMUsersTableSection from "./InactiveFBMUsersTableSection/InactiveFBMUsersTableSection";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "InactiveFBMUsersTable",
    components: {
      // InactiveFBMUsersTableAdmin,
      InactiveFBMUsersTableSection,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getInactiveFBMUsersForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterUserName: '',
        filterId: '',
        filterUserId: '',
        filterTransactionNumber: '',
        filterItemName: '',
        filterTrackingNumber: '',
        filterZip: '',
        filterContactName: '',
        filterEmail: '',
        filterTotalAmount: '',
        filterTransactionKeyword: '',
        filterShopName: '',

        countFilterParams: 0,
        filterGetParams: {},

        isModalInfoPopup: false,
        itemId: -1,
      }
    },

    mounted() {
      this.filter()
    },

    // beforeDestroy() {
    //   if (this.$store.getters.getRequestReturnSocket) {
    //     this.destroySocketChannel('InactiveFBMUsersRefund', 'setInactiveFBMUsersSocket')
    //   }
    // },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          transactionNumber: this.$route.query.transactionNumber,
          itemName: this.$route.query.itemName,
          trackingNumber: this.$route.query.trackingNumber,
          zip: this.$route.query.zip,
          contactName: this.$route.query.contactName,
          email: this.$route.query.email,
          totalAmount: this.$route.query.totalAmount,
          transactionKeyword: this.$route.query.transactionKeyword,
          shopName: this.$route.query.shopName,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextInactiveFBMUsersPage', true)
        this.$store.dispatch('fetchInactiveFBMUsers', url).then(() => {
          this.$store.commit('setNextInactiveFBMUsersPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'ForbiddenPaymentTransactionInstanceId',
            filterUserId: 'userId',
            filterTransactionNumber: 'transactionId',
            filterItemName: 'transactionItems',
            filterTrackingNumber: 'transactionTrackingNumber',
            filterZip: 'transactionContactZipCode',
            filterContactName: 'transactionContactFullName',
            filterEmail: 'transactionContactEmail',
            filterTotalAmount: 'transactionWarehouseAmount',
            filterTransactionKeyword: 'ForbiddenPaymentTransactionKeyword',
            filterShopName: 'store',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn('between_created_at', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportInactiveFBMUsers', {filter: url, type: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.createDownloadLink({data: response.data, name: `Inactive FBM Users.${type}`, type: type})
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },




    }

  }
</script>

<style scoped>

</style>
