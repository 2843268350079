<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_date'])"></span>
        <DatePickerDefault
            :label="$t('common_date.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
import DatePicker from 'vue2-datepicker'
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
// import {filterCountriesUSA} from "../../../../../../../services/filterCountries";

export default {
  name: "InactiveFBMUsersTableUserFilter",
  components: {
    SearchSelect,
    DatePicker,
    DatePickerDefault,
    FilterBlock,
    MainButton,
    // DefaultInput,
  },

  mixins: [filterFunc, usersFunc],

  props: {
    navTabs: Object,
    showFilter: Boolean,
    filterGetParams: Object,
    selectHandmade: Array,
  },

  mounted() {
    // this.$store.dispatch('fetchCountries').then(() => {
    //   let countries = this._.toArray(this.$store.getters.getCountries)
    //   filterCountriesUSA(countries)
    //   this.optionsCountries = countries;
    //   this.getCountrySelected()
    // }).catch(error => this.createErrorLog(error))
  },

  data(){
    return {
      date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
      id: this.filterGetParams.id ? this.filterGetParams.id : '',
      transactionNumber: this.filterGetParams.transactionNumber ? this.filterGetParams.transactionNumber : '',
      itemName: this.filterGetParams.itemName ? this.filterGetParams.itemName : '',
      trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
      zip: this.filterGetParams.zip ? this.filterGetParams.zip : '',
      contactName: this.filterGetParams.contactName ? this.filterGetParams.contactName : '',
      email: this.filterGetParams.email ? this.filterGetParams.email : '',
      totalAmount: this.filterGetParams.totalAmount ? this.filterGetParams.totalAmount : '',
      transactionKeyword: this.filterGetParams.transactionKeyword ? this.filterGetParams.transactionKeyword : '',
      shopName: this.filterGetParams.shopName ? this.filterGetParams.shopName : '',

      userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
      userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

      options: [],

      filterCounts: [
        'date',
        'id',
        'transactionNumber',
        'itemName',
        'trackingNumber',
        'zip',
        'contactName',
        'email',
        'totalAmount',
        'transactionKeyword',
        'shopName',

        'userName',
        'userId',
      ],
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      this.id = newVal.id ? newVal.id : ''
      this.transactionNumber = newVal.transactionNumber ? newVal.transactionNumber : ''
      this.itemName = newVal.itemName ? newVal.itemName : ''
      this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
      this.zip = newVal.zip ? newVal.zip : ''
      this.contactName = newVal.contactName ? newVal.contactName : ''
      this.email = newVal.email ? newVal.email : ''
      this.totalAmount = newVal.totalAmount ? newVal.totalAmount : ''
      this.transactionKeyword = newVal.transactionKeyword ? newVal.transactionKeyword : ''
      this.shopName = newVal.shopName ? newVal.shopName : ''

      this.userName = newVal.userName ? newVal.userName : ''
      this.userId = newVal.userId ? newVal.userId : ''
    },

  },

  methods: {
    closeFilter() {
      this.$emit('close')
    },

    getCountrySelected(){
      if(this.countryId === '') return ''
      return this._.find(this.optionsCountries, {id: parseInt(this.countryId)})
    },

    resetFilter() {
      let data = this.resetFilterParams(this, this.filterCounts)

      this.$emit('changeFilter', data)
    },

    changeFilter() {
      let data = this.changeFilterParams(this, this.filterCounts)
      data.date = this.changeDateParams(this, 'date')

      this.$emit('changeFilter', data)
    },

  },
}
</script>

<style scoped>

</style>
