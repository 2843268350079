<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getInactiveFBMUsers.length > 0 && $store.getters.getInactiveFBMUsersLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'forbiddenGoods_ID',
            'common_date',
            'forbiddenGoods_Transaction',
            'forbiddenGoods_Store',
            'forbiddenGoods_InactiveFBMUsersKeyword',
            'common_manage',
            'forbiddenGoods_ToBalance',
            'forbiddenGoods_Refund',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th>{{ $t('inactiveFBMUsers_id.localization_value.value') }}</th>
            <th>{{ $t('inactiveFBMUsers_created.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('inactiveFBMUsers_storage.localization_value.value') }}</th>
<!--            <th>{{ $t('forbiddenGoods_Store.localization_value.value') }}</th>-->
<!--            <th>{{ $t('forbiddenGoods_InactiveFBMUsersKeyword.localization_value.value') }}</th>-->
            <th width="100%" class="hide-1400"></th>
<!--            <th v-if="isAdmin">{{ $t('common_manage.localization_value.value') }}</th>-->
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getInactiveFBMUsers" :key="index">
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                  class="empty-label"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              <span class="table-link btn-style">
                 #{{ item.id }}
              </span>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="isAdmin">
<!--              <TableUserColumn-->
<!--                  v-if="isAdmin"-->
<!--                  :item="item.payment_transaction"-->
<!--              />-->
              {{ item.name }}
            </td>
            <td>
              {{ item.storage }}
            </td>

            <td class="hide-1400"></td>
<!--            <td class="p-0">-->

<!--            </td>-->
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getInactiveFBMUsers"
                 :key="index">
              <InactiveFBMUsersTableMobile
                  :item="item"
                  @changeInfoPopup="$emit('changeInfoPopup', true, item.payment_transaction.id)"
                  @approve="$emit('approve', item.id)"
                  @refund="$emit('refund', item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getInactiveFBMUsersCommonList.next_page_url !== null && $store.getters.getInactiveFBMUsers.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getInactiveFBMUsersCommonList.total - $store.getters.getInactiveFBMUsersForPage < $store.getters.getInactiveFBMUsersForPage ?
                    $store.getters.getInactiveFBMUsersCommonList.total - $store.getters.getInactiveFBMUsersForPage:
                    $store.getters.getInactiveFBMUsersForPage"
            />

            <ExportBtn
                class="table-bottom-btn__right"
                @downloadFiles="type => $emit('downloadFiles', type)"
            />
          </div>
        </div>


      </div>
    </template>

    <template
        v-if="$store.getters.getInactiveFBMUsersLoading === false && $store.getters.getInactiveFBMUsers.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import InactiveFBMUsersTableMobile from "./InactiveFBMUsersTableMobile/InactiveFBMUsersTableMobile";
// import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
// import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
// import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {routeFilter} from "../../../../../../../mixins/routeFilterMixins/routeFilter";
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'

export default {
  name: "InactiveFBMUsersTable",
  components: {
    ExportBtn,
    // ValueHelper,
    // DefaultCheckbox,
    // TableUserColumn,
    InactiveFBMUsersTableMobile,
    NoResult,
    // DefaultCheckbox,
    ShowMore,
  },

  mixins: [mixinDetictingMobile, routeFilter],

  props: {
    countFilterParams: Number,
    selectedNow: Boolean,
    isModalInfoPopup: {
      type: Boolean,
      default: false,
    },
    itemId: Number,
  },

  data() {
    return {
      showFilter: false,
      isMobile: false,

      showContent: false,

      // isModalProductsToWarehouse: false,

      show1: false,
      show2: false,

    }
  },

  methods: {



  }
}
</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .comment-row {
    width: 100px;
  }
}

.table-manage-list .manager-btn {
  width: 200px;
}

@media (max-width: 1400px) {
  .table-manage-list .manager-btn {
    width: 100px;
  }

  .user-field-admin {
    flex-wrap: wrap;
    display: flex;
  }

  .hide-1400 {
    display: none;
  }
}

/*.site-table thead th:first-child,*/
/*.site-table tbody td:first-child{*/
/*padding-right: 0;*/
/*}*/

/*.site-table thead th:nth-child(2),*/
/*.site-table tbody td:nth-child(2){*/
/*padding-left: 0;*/
/*}*/

/*.site-table thead th:last-child,*/
/*.site-table tbody td:last-child{*/
/*padding-right: 0;*/
/*}*/

.small-table th,
.small-table td {
  white-space: nowrap;
}

.size-24 {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}


</style>
